.DateInput_input {
  font-size: 13px;
  line-height: 13px;
  padding: 0;
  text-align: center;
  background-color: #f8f8f8;
  /* border-top-left-radius: 6px;
    border-top-right-radius: 6px; */
}

.DateInput {
  width: 85px;
  line-height: 19px;
  background-color: #f8f8f8;
  /* border-radius: 6px; */
}

.DateRangePicker_picker__openDown {
  top: 50px;
  z-index: 201;
}

.DateInput__openDown:after {
  top: 39px;
}

.DateInput__openDown:before {
  top: 39px;
}

.DateRangePickerInput_arrow_svg {
  height: 20px;
  width: 16px;
  background-color: #f8f8f8;
}

.DateRangePickerInput {
  /* border-top-left-radius: 6px;
    border-top-right-radius: 6px; */
  line-height: 17px;
  background-color: #f8f8f8;
  border: 1px solid #a6a6a6;
}

.DayPickerKeyboardShortcuts_show__topRight {
  display: none;
}
