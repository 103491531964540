@font-face {
    font-family: 'Lato'; /*a name to be used later*/
    font-display: swap;
    src: url('../fonts/Lato/Lato-Regular.woff2') format('woff2'),
    url('../fonts/Lato/Lato-Regular.woff') format('woff'),
    url('../fonts/Lato/Lato-Regular.ttf') format('truetype'); /*URL to font*/
}

@font-face {
    font-family: 'Lato-Light';
    font-display: swap;
    src: url('../fonts/Lato/Lato-Light.woff2') format('woff2'),
    url('../fonts/Lato/Lato-Light.woff') format('woff'),
    url('../fonts/Lato/Lato-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato-Bold';
    font-display: swap;
    src: url('../fonts/Lato/Lato-Bold.woff2') format('woff2'),
    url('../fonts/Lato/Lato-Bold.woff') format('woff'),
    url('../fonts/Lato/Lato-Bold.ttf') format('truetype');
}

html, body {
    font-family: 'Lato', sans-serif;
    height: 100%;
}

body {
  overflow-y: hidden;
}

/*Grammarly fix*/
grammarly-ghost div {
  background: none 0% 0% / auto repeat scroll padding-box border-box rgba(0,0,0,0) !important;
}

#root {
    height: 100%;
}

#root > div {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    overflow: hidden;
}
