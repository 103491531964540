/* Now we have full control over intercom widget */

.intercom-launcher {
    backface-visibility: hidden;
    cursor: pointer;
    font-family: Helvetica, Arial, sans-serif;
    opacity: 0;
    outline: 0;
    position: relative;
    display: flex;
    transform-origin: center;
    transition: opacity 0.25s ease, transform 0.25s ease;
    height: 23px;
    opacity: 1;
    transform: scale(0);
    width: 23px;
    margin-top: 14px;
  }
  .intercom-launcher:focus {
    outline: 0;
  }
  .intercom-launcher.intercom-booted {
    transform: scale(1);
  }
  .intercom-launcher * {
    cursor: pointer;
  }

  .intercom-unread-count {
    backface-visibility: hidden;
    background: #fc576b;
    border-radius: 10px;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    color: #fff;
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    min-width: 14px;
    opacity: 0;
    padding: 0 4px;
    position: fixed;
    right: -2px;
    text-align: center;
    top: -2px;
    transform: scale(0.5);
    transform-origin: center;
    transition: opacity 0.15s ease, transform 0.15s ease;
  }
  .intercom-unread-count.active {
    opacity: 1;
    transform: scale(1);
  }

  #intercom-container .intercom-messenger-frame {
    bottom: 0px !important;
    top: 50px;
  }

  .intercom-icon-open,
  .intercom-icon-close {
    background-repeat: no-repeat;
    border-radius: 50%;
    bottom: 0;
    position: fixed;
    top: 0;
    transition: transform 0.16s linear, opacity 0.08s linear;
    width: 100%;
    background-position: center;
    background-size: contain;
  }

  .intercom-icon-open {
    background-image: url('../assets/images/new-designs/intercom-new-icon.svg');
    opacity: 1;
    transform: rotate(0deg) scale(1);
  }
  .intercom-open .intercom-icon-open {
    opacity: 0;
    transform: rotate(30deg) scale(0);
  }

  .intercom-icon-close {
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcBAMAAACAI8KnAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAIVBMVEUAAAD///////////////////////////////////8AAADPn83rAAAACXRSTlMACq47u/I8r7wWzHxoAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAALEgAACxIB0t1+/AAAAJJJREFUGNNdzzEKhDAQBdAvwtYWW9hbbSdCDrBnWBDS2Sx7A8HSKwgic1tNxj/jmirDC5P/UTSw01V4ri2nMr7xkg/HIAu+Qi6j9HhEcpB1gHFGGCuSTyQTlQ2Vg3ic4x49TVpzwcQXvI+3x/+r0p9eLAfyYhrIWNOSmfZkVlH2Kpm9Z+bJeh68oSYmnlGMnv1X7RZ2SET5id+LAAAAAElFTkSuQmCC");
    opacity: 0;
    transform: rotate(-30deg);
    background-size: 30%;
  }
  .intercom-open .intercom-icon-close {
    opacity: 1;
    transform: rotate(0deg);
  }

  #intercom-container iframe.intercom-launcher-frame {
    height: 0px !important;
    width: 0px !important;
}

  /* iframe content */
  #intercom-container .intercom-launcher {
    height: 0px;
    width: 0px;
}


.intercom-namespace .intercom-app-launcher-enabled .intercom-messenger-frame {
    height: calc(100% - 20px - 80px - 20px);
    top: calc(20px + 80px);
}

#intercom-container .intercom-launcher-badge-frame {
    height: 0px!important;
}