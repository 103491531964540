@import '~react-baron/src/styles.css';

.bar {
  background: #d8e8ef !important;
}

.quick-reply-tooltip .rc-tooltip-arrow {
  top: 40px !important;
}

.quick-reply-tooltip .rc-tooltip-inner {
  border: none !important;
  padding: 0 !important;
}

.quick-reply-tooltip .rc-tooltip-arrow {
  border-right-color: transparent !important;
}

.quick-reply-tooltip .rc-tooltip {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.rc-tooltip {
  opacity: 1 !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.29);
  padding: 0 !important;
  border-radius: 3px;

  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
  animation-duration: 0.1s;
  animation-name: fadeIn;
}

.rc-tooltip-hidden {
  display: block !important;
  visibility: hidden !important;
  animation-name: fadeOut;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.rc-tooltip-arrow {
  border-bottom-color: #b1b1b1;
}

.expert-widget-stats-tooltip .rc-tooltip-arrow {
  border-bottom-color: none;
}

.expert-widget-stats-tooltip.rc-tooltip {
  left: 53px !important;
}

@media screen and (min-height: 800px) {
  .expert-widget-stats-tooltip.rc-tooltip {
    left: 59px !important;
  }
}

@media screen and (min-height: 900px) {
  .expert-widget-stats-tooltip.rc-tooltip {
    left: 65px !important;
  }
}

/* 12-column grid with some padding for small screen sizes */
@media screen and (max-width: 1450px) {
  .app-width-wrapper {
    width: 100% !important;
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
}

/* switch over to a 10-column grid for large screen sizes */
@media screen and (min-width: 1450px) {
  .app-width-wrapper {
    width: 83.33333333% !important;
    margin-left: 8.33333333% !important;
    margin-right: 8.33333333% !important;
  }
}

.page-header-row {
  height: 7rem;
}

.center-row {
  height: calc(100% - 9rem);
  min-height: calc(100% - 9rem);
  background-color: #4797c4;
}

.logo {
  line-height: 7rem;
}

.app-name {
  line-height: 7rem;
  vertical-align: center;
  color: #0a225f;
  font-family: lato, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  padding: 0;
}

/* TODO box shadow is not working for some reason */
.top-navbar {
  border-bottom: 2px solid #b7cad3;
  z-index: 5;
  position: relative;
}

.top-right-col {
  height: 7rem;
}

.profile-pic {
  width: 28px;
  border-style: solid;
  border-width: 1px;
  border-color: #44b1df;
  background-color: transparent;
  border-radius: 30px;
  overflow: hidden;
}

.profile-pic-circle {
  position: absolute;
  bottom: 2.5rem;
  right: 2.2rem;
}

.top-right-container {
  line-height: 1.1rem;
  color: #243a70;
  font-family: lato, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  position: absolute;
  bottom: 1rem;
  right: 1.5rem;
  text-align: right;
}

.top-right-container span {
  display: inline-block;
  vertical-align: middle;
}

.top-right-container a,
.top-right-container a:hover {
  color: inherit;
  text-decoration: inherit;
}

.top-right-container .logout-image {
  margin-right: 0.4rem;
}

.top-right-container .divider {
  margin-left: 1rem;
  margin-right: 1rem;
}

.widget-container-row {
  height: calc(100% - 5rem);
}

.widget-label-row {
  color: #ffffff;
  font-size: 1.8rem;
  line-height: 5rem;
  font-family: lato, sans-serif;
  font-weight: 400;
}

.widget {
  background-color: #ffffff;
  z-index: 3;
  height: 100%;
  overflow: auto;
}

.selected {
  background-color: #edf3f7;
  color: #000000;
  font-weight: 400;
}

.unselected {
  cursor: pointer;
  color: #7f7f7f;
  font-weight: 500;
}

.window-top-row {
  height: 45px;
}

.row-divider-row {
  height: 3.3rem;
}

.row-divider {
  background-color: #ffffff;
  z-index: 8;
  height: 100%;
}

.msg-row {
  height: 7rem;
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .msg-row {
    height: 8rem;
  }
}

.msg-icon-container {
  width: 2.5rem;
  float: left;
  padding-top: 0.9rem;
  height: 100%;
}

.msg-icon {
  width: 1.6rem;
  max-width: 100%;
  max-height: 100%;
}

.msg-icon-large {
  width: 3rem;
  margin-top: 2rem;
}

.msg-content-left {
  width: 90%;
  margin-left: 2rem;
}

.msg-sender {
  line-height: 1.9rem;
  font-family: lato, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
}

.msg-sender-queue {
  margin-top: 0.9rem;
}

.msg-sender-chat {
  margin-top: 1.2rem;
}

.msg-subject {
  font-family: lato, sans-serif;
}

.msg-subject-queue {
  margin-top: 0.2rem;
  font-size: 1.3rem;
  line-height: 1.6rem;
  min-height: 1.6rem;
}

.msg-subject-chat {
  margin-top: 0.8rem;
  font-size: 1.6rem;
  line-height: 2rem;
  min-height: 2rem;
}

.msg-body {
  margin-top: 0.3rem;
  font-family: lato, sans-serif;
  font-size: 1rem;
  line-height: 1.3rem;
}

.msg-for-smb {
  margin-top: 1.6rem;
  text-align: right;
  font-size: 1.5rem;
  line-height: 1.9rem;
  font-family: lato, sans-serif;
  font-weight: 700;
}

.msg-sent-time {
  font-family: lato, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.3rem;
  margin-top: 0.6rem;
}

.ticket-unselected .msg-body,
.ticket-unselected .msg-for-smb,
.ticket-unselected .msg-sent-time {
  color: #7f7f7f;
}

.msg-divider {
  background-color: #7f7f7f;
  opacity: 0.2;
}

.highlight {
  background-color: #f9f9f9;
}

.msg-summary-container {
  width: 90%;
  margin-left: 5%;
}

.msg-detail-row {
  height: 75%;
  background-color: #ffffff;
  padding-top: 2%;
}

.msg-body-row {
  height: calc(100% - 19.3rem);
}

.msg-body-col {
  height: 100%;
  font-size: 1.3rem;
  font-weight: 400;
  color: #7f7f7f;
  padding-top: 2rem;
  overflow-y: scroll;
}

.msg-partial-body-row {
  height: 25%;
}

.msg-partial-body-col {
  z-index: 8;
  height: 80%;
  font-size: 1.3rem;
  font-weight: 400;
  color: #7f7f7f;
  top: 2rem;
  position: relative;
  overflow-y: scroll;
}

.mini-stats-col {
  text-align: center;
  background-color: #0bc495;
  height: 7rem;
}

.mini-stats-label {
  line-height: 1.5rem;
  background-color: #efefef;
  color: #243a70;
  font-family: lato, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  text-align: left;
}

.mini-stats {
  background-color: #0bc495;
  line-height: 3.4rem;
  color: #ffffff;
  font-family: lato, sans-serif;
  font-size: 2.8rem;
  font-weight: 300;
  letter-spacing: 0.1rem;
  text-align: center;
  padding: 0;
}

.mini-stats-desc {
  background-color: #0bc495;
  color: #ffffff;
  font-family: lato, sans-serif;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: 0.1rem;
  line-height: 0.8rem;
  text-align: center;
  padding: 0;
}

.btn-simplr {
  background-color: #44b1df;
  border: 0;
  border-radius: 2.2rem;
  color: #ffffff;
  font-family: lato, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.3rem;
  padding: 0.5rem 2rem;
  cursor: pointer;
  transition: 0.3s;
}

.btn-simplr-disabled {
  opacity: 0.5;
  cursor: default;
}

.btn-simplr:hover,
.btn-simplr:active,
.btn-simplr:focus {
  background-color: #545454;
  color: #ffffff;
}

.btn-row-button {
  margin-top: 1.5rem;
  margin-left: 1rem;
}

.green-button {
  background-color: #0bc495;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.full-height {
  height: 100%;
}

.message-padding {
  padding: 20px 0;
}

.message-text-padding {
  padding-left: 0.2rem;
}

.msg-old-container-row {
  height: calc(100% - 20.5rem);
  overflow-y: scroll;
}

.msg-read-icon {
  background-image: url(../assets/images/message-read-icon.svg);
  background-repeat: no-repeat;
  background-position: center center;
  width: 4rem;
  height: 4rem;
  background-color: #ffd058;
  border-radius: 0.8rem;
}

.ticket-selected {
  cursor: auto;
  background-color: #5a89cc;
  color: #ffffff;
}

.msg-selected {
  background-color: #5a89cc;
  color: #efefef;
}

.width-full {
  width: 100%;
}

.new-message-row {
  height: 5.2rem;
  border-radius: 0 0 1rem 1rem;
}

.add-message-bg {
  background-color: #edf3f7;
}

.add-note-bg {
  background-color: #ffe8bb;
}

.add-message-fg {
  color: #545454;
}

.add-note-fg {
  color: #916c27;
}

.message-type-container {
  width: 17rem;
}

.new-message-container {
  width: calc(100% - 21rem);
}

.message-type-link {
  cursor: pointer;
  font-family: lato, sans-serif;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.3rem;
}

.new-message-form {
  margin-top: 0.4rem;
}

.newMessage {
  width: 100%;
  background-color: #ffffff;
  border-radius: 1rem;
  border: 0;
  font-size: 1.3rem;

  margin-top: 0.75rem;
  height: 3rem;
  padding-top: 0.5rem;
  padding-left: 0.5rem;
}

.new-message {
  color: #acadac;
}
.new-message::-webkit-input-placeholder {
  color: #acadac;
}
.new-message:-moz-placeholder {
  color: #acadac;
}
.new-message::-moz-placeholder {
  color: #acadac;
}
.new-message:-ms-input-placeholder {
  color: #acadac;
}
.new-message::-ms-input-placeholder {
  color: #acadac;
}

.new-note {
  color: #ba9252;
}
.new-note::-webkit-input-placeholder {
  color: #ba9252;
}
.new-note:-moz-placeholder {
  color: #ba9252;
}
.new-note::-moz-placeholder {
  color: #ba9252;
}
.new-note:-ms-input-placeholder {
  color: #ba9252;
}
.new-note::-ms-input-placeholder {
  color: #ba9252;
}

.send-message-container {
  width: 4rem;
}

.sendMessage {
  padding: 0;
  line-height: 5rem;
  vertical-align: middle;
}

.outbound-message {
  background-color: #f5f8fb;
}

.outbound-note {
  background-color: #fcf0e1;
}

.smb-message {
  background-color: #e3f5f4;
}

.hidden {
  display: none;
}

.message-divider-white {
  border-top: 1px solid #ffffff;
}

.message-divider-gray {
  border-top: 1px solid #efefef;
}

.message-body-text {
  color: #7f7f7f;
  font-size: 1.4rem;
  font-family: lato, sans-serif;
  font-weight: 300;
  line-height: 1.7rem;
  margin-top: 0.5rem;
  white-space: pre-wrap;
}

.hide {
  display: none;
}

.cursor {
  cursor: pointer;
}

.left-component {
  margin-right: 0.1rem !important;
}

.right-component {
  margin-left: 0.1rem !important;
}

.bottom-image {
  position: absolute;
  width: 500px;
  height: 92px;
  top: -85px;
  left: 53%;
  transform: translate(-50%);
}

.divider {
  z-index: 0 !important;
}
